.title-bar .list-group-item{
 min-width: 100px;
}
.user-list .list-group{
    border-bottom: 1px solid rgb(231, 230, 230);
}
.user-list .list-group:hover{
    background: #eeeeee;
}
.list-group .date{
    color: #0f6cbd;
}
.search-box {
    border-radius: 12px;
    background: #eeeeee;
    border:0;
    padding-left:10px;
}
.h-90{
    height: 90vh;
}
.h-80{
    height: 80vh;
}