.page-wrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
}
.body-wrapper{
    flex: 1;
    background-color: rgba(240, 243, 246, 0.922);
}
.content{
    width: 100%;
}