@media all {
    .page-break {
        display: none;
    }
}

table th,
td {
    border: 1px solid silver;
    padding-right: 4px;
    padding-left: 4px;
}

.prin-area {
    margin: 0 15% 0 15%;
}

th {
    font-size: 10px;
    font-weight: bold;
    padding-bottom: 5px;
    padding-top: 5px;
}

input {
    border: none;
}

@media print {
    @page {
        size: auto;
        margin: 8mm;
    }

    body {
        padding-top: 8mm;
        margin: 8mm;
    }

    html,
    body {
        height: auto;
        margin: 0 !important;
        padding: 8mm !important;
        overflow: hidden;
    }
    table th,
    td {
        border: 1px solid silver;
        padding-right: 4px;
        padding-left: 4px;
    }
}