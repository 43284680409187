.fileupload input[type=file]{
    font-size: 10px;
    margin-bottom: 4px;
    background: #dfdfdf;
    padding: 4px;
    width: 100%;
    border-radius: 4px;
}
.fileupload input[type=submit] {
    font-size: 14px;
    margin-bottom: 4px;
    background: #dfdfdf;
    padding: 4px;
    border: none;
    border-radius: 4px;

}
.fileupload{
    border-radius: 5px;
    padding: 5px;
    background: #f7f7f7;
}