.float-input {
    position: relative;
    display: inline-block;
    width: 100% !important;
}

.float-input label {
    position: absolute;
    padding: 0 4px 0 4px;
    background: #fff;
    left: 10px;
    top: 0px;
    border-radius: 5px;
    bottom: 0;
    display: inline-table;
    margin: auto;
}

.float-input .form-control[type=file]::file-selector-button {
    background-color: white;
    font-size: 10px;
    margin-left: .5px;
    padding: 2px;
}

.float-input {
    display: inline-block;
}

.float-input input:focus ~ label {
    font-size: 11px;
    top: -6px;
    bottom:auto;

}

.float-input input:not(:placeholder-shown) ~ label {
    font-size: 11px;
    top: -6px;
    bottom:auto;
}

.float-input .dropdown-item {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 10px;
}

.float-input .dropdown-menu {
    border: 1px solid rgba(128, 128, 128, 0.14) !important;
    width: 100% !important;
}

.float-input .dropdown-toggle::after {
    position: absolute;
    top: 0;
    bottom: 0;
    margin-top: 15px;
    margin-right: 8px;
    right: 0;
    cursor: pointer;
}

.float-input select:focus ~ label {
    font-size: 11px;
    top: -6px;

}

.float-input select:not(:placeholder-shown) ~ label {
    font-size: 11px;
    top: -6px;
}
.invalid-feedback{
    position: absolute;
    background: #1b336e;
    padding: 3px;
    border-radius: 4px;

}
