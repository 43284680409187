.card {
    border-radius: 0;
}
.tile-icons .icon{
    font-size: 22px;
}

.num {
    font-weight: bold;
    font-size: 15px;
}

.text-info1{
   margin-top: 5px;
    color: blue;
    font-size: larger;
}
.text-info2{
    color: red;
    margin-top: 5px;
    font-size: larger;
}
.text-info3{
    color: rgb(244, 188, 3);
    margin-top: 5px;
    font-size: larger;
}
.text-info4{
    color: rgb(51, 168, 12);
    margin-top: 5px;
    font-size: larger;
}

