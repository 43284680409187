.title-bar .list-group-item {
    min-width: 100px;
}

.company-list .list-group {
    border-bottom: 1px solid rgb(231, 230, 230);
}

.company-list .list-group:hover {
    background: #eeeeee;
}

.list-group .date {
    color: #0f6cbd;
}

.search-box {
    border-radius: 12px;
    background: #eeeeee;
    border: 0;
}

.h-90 {
    height: 90%;
}

.figure-caption .caption {
    margin-right: 10px;
    font-size: 10px;

}

.bar-menu input,.bar-menu .float-input {
    margin-top: 0px!important;
}
td{
    text-align: center;
}
