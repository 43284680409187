ul{
    padding: 0;
    margin: 0;
}
li{
    list-style: none;
}
body{
    background-color: #eee;
    font-family: 'Open Sans', sans-serif;
}



.side-menu{
    position: relative;
    background: #000;
    width: 50px;
    height: available;
    box-sizing: border-box;
    padding: 15px 4px;
    transition: width .2s ease-in;
}
.side-menu.inactive{
    width: 50px;
}

.side-menu .top-section{
    position: relative;
}

.side-menu .top-section .logo{
    width: 30px;
    height: 30px;
    overflow: hidden;
    display: inline-block;
}
.side-menu .top-section .logo img{
    max-width: 100%;
    max-height: 100%;
}

.side-menu .top-section .toggle-menu-btn{
    color: #666;
    font-size: 20px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    cursor: pointer;
}

.side-menu.inactive .top-section .toggle-menu-btn{
    right: -50px;
}

.side-menu .search-controller{
    color: #fff;
    position: relative;
}

.side-menu .search-controller .search-btn{
    width: 40px;
    height: 35px;
    position: absolute;
    background: transparent;
    border: 0;
    font-size: 20px;
    color: #666;
}

.side-menu .search-controller input[type=text]{
    border: 0;
    outline: none;
    height: 35px;
    background: #333;
    border-radius: 5px;
    display: block;
    margin: 20px 0;
    width: 100%;
    box-sizing: border-box;
    padding-left: 40px;
    color: #666;
}

.side-menu .main-menu .menu-item{
    color: #333;
    text-decoration: none;
    font-size: 15px;
    display: block;
    font-weight: 600;
    cursor: pointer;
    text-align: center;
}

.side-menu .main-menu .menu-item.active{
    background: #64B5F6;
    border-radius: 5px;
    color: #2e2c2c;
}


.side-menu .main-menu{
    margin: 5px 0;
    position: relative;
    min-height: 250px;
    overflow-y: scroll;
    overflow-x: hidden;
}
/* not required any more */
.side-menu.inactive .main-menu{

}

.side-menu .main-menu .menu-item .menu-icon{
    display: inline-block;
    font-size: 25px;
    text-align: center;
}
.side-menu .main-menu .menu-item .menu-icon *{
    font-size: 18px;
}

.side-menu .menu-item span,.side-menu .sub-menu span{
    display: inline-block;
    opacity: 1;
    transition: opacity .2s ease-in;
    font-size: 12px;
    font-weight: normal;
}

.side-menu.inactive .main-menu .menu-item span{
    opacity: 0;
    width: 0;
    height: 0;
    overflow: hidden;
}
.side-menu .main-menu ul {
    padding-left: 2px;
}

.side-menu .main-menu .sub-menu{
    color: #333;
    background: #f1f0f0;
    box-sizing: border-box;
    text-align: center;
    max-height: 0;
    overflow: hidden;
    transition: max-height .2s ease-in;
}



.side-menu .main-menu .sub-menu.active{
    max-height: 200px;
}

.side-menu .main-menu .sub-menu a{
    display: block;
    margin: 5px 0;
    font-size: 15px;
    font-weight: 600;
    text-decoration: none;
    color: #333;
    box-sizing: border-box;
    padding: 5px;
}


.side-menu .main-menu .sub-menu a.active{
    /* change in background, not recorded in video */
    background: #64B5F6;
    border-radius: 5px;
    color: #2e2c2c;
    display: block;

}

.side-menu-footer{
    width: 100%;
    background: #333;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 20px;
    box-sizing: border-box;
}

.side-menu-footer .avatar{
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 50%;
    display: inline-block;
}
.side-menu-footer .avatar img{
    max-width: 100%;
}

.side-menu-footer .user-info{
    display: inline-block;
    margin: 0 10px;
    color: #fff;
    position: absolute;
    opacity: 1;
    transition: opacity .2s ease-in;
}

.side-menu.inactive .side-menu-footer .user-info{
    opacity: 1;
    width: 0;
    height: 0;
    overflow: hidden;
}


.side-menu-footer .user-info h5{
    font-size: 15px;
}


.side-menu-footer .user-info p{
    font-size: 11px;
    font-weight: normal;
}

.divider{
    width: 100%;
    height: 1px;
    border-radius: 1px;
    background: #333;
}
.sb-sidenav-toggled{
    display: block;
}
