.pointer{
    cursor: pointer;
}
.icons{
    text-align: center;
}
td label{
    width: 100%!important;
}
body{
    overflow-y: hidden!important;
}
td{
    padding: 0;
    position: relative;
}
td:first-child{
    text-align: center;
    font-size: medium;
}
td *{
    font-weight: 400;

}
td input{
    width: 100%;
    padding-left: 4px;
    padding-right: 4px;
    height: 26px;
}
td input:focus{
    border: 1px solid rgb(21, 88, 110)!important;
    box-shadow: none;
}
td input[disabled]{
    outline: 0!important;
    background: none;
    box-shadow: none;
    border: none!important;
    width:100%!important
}
td .btn{
    padding:4px;
}

.show-on-focus{
    position: absolute;
    z-index: 999;
    background: rgb(224, 220, 220);
    padding: 8px;
    max-width:100%;
    min-width:200px;
    margin-top: 6px;
    border-radius:8px;
    max-height: 100px;
    overflow-y: auto;
    overflow-x: hidden;

}
.show-on-focus li{
    padding: 4px 8px 4px 8px;
}
li.active{
    background: blue;
    color:aliceblue
}
.danger{
    border: 1px solid red!important;
    background-color: brown!important;
    color:antiquewhite!important
}
input.checkbox{
    width: 14px!important;
    height: 14px!important;
    margin-right: 4px;
}
